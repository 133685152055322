
// ***********************************************************************
// ** Mindermaster
// ** Real Time Analyzer
// ***********************************************************************

import React from 'react';

class RTATypeB extends React.Component
{
  constructor(props)
  {
    super(props);

    this.canvasRef = React.createRef();

    this.state = 
    {
      angle: 0,
    }

    this.updateAnimation = this.updateAnimation.bind(this);
    this.vsync = this.vsync.bind(this);
    this.frameCounter = 0;
    this.barCurrentAngle = 0;
    this.barSine = 0;
    this.numberOfBars = 30;
    this.barWidth = 2;
    this.barMaxHeight = 320;
    this.barSpacing = 1;

    this.barAmplitudes = [];
    this.barAngles = [];

    // let initialAmplitude = 1;
    // for (var i=0; i<this.numberOfBars; i++)
    // {
    //   this.barAmplitudes[i] = initialAmplitude;
    //   initialAmplitude += 6;
    // }

    // let initialAngle = 0;
    // for (var i=0; i<this.numberOfBars; i++)
    // {
    //   this.barAngles[i] = initialAngle;
    //   initialAngle += 0.72;
    // }
  }

  componentDidMount()
  {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0,0,canvas.width, canvas.height);
    // this.raf = requestAnimationFrame(this.updateAnimation);



    this.startAnimation()
  }

  componentWillUnmount()
  {
    this.stopAnimation();
  }

  startAnimation()
  {
    if (!this.frameCounter)
    {
      this.frameCounter = window.requestAnimationFrame(this.vsync);
    }
  }

  vsync()
  {
    try
    {
      this.updateAnimation();
    }
    catch(err)
    {
      console.log("Please Wait...")
    }

    this.frameCounter = window.requestAnimationFrame(this.vsync);
  }

  stopAnimation()
  {
    window.cancelAnimationFrame(this.frameCounter);
  }

  updateAnimation()
  {
    // var width = ctx.canvas.width;
    // var height = ctx.canvas.height;
    // var freqData = new Uint8Array(analyser.frequencyBinCount);
    // var scaling = height / 256;
    // analyser.getByteFrequencyData(freqData);
    // ctx.fillStyle = 'rgba(0, 20, 0, 0.1)';
    // ctx.fillRect(0, 0, width, height);
    // ctx.lineWidth = 2;
    // ctx.strokeStyle = 'rgb(0, 200, 0)';
    // ctx.beginPath();
    // for (var x = 0; x < width; x++)
    //   ctx.lineTo(x, height - freqData[x] * scaling);
    // ctx.stroke();
    // console.log("componentDidUpdate");

    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    const angle = this.state.angle * 1;

    // ctx.clearRect(0,0, canvas.width, canvas.height);
    // ctx.globalAlpha = alpha;

    context.save();
    context.beginPath();
    // context.clearRect(0,0,canvas.width, canvas.height);

    // context.translate(canvas.width/2, canvas.height/2);
    // context.rotate((angle * Math.PI) / 180);
    // context.fillStyle = "rgba(0,0,0,0.24)";
    // context.fillStyle = "rgba(0,0,0,1)";

    // context.fillRect(0, 0, this.props.renderwareWidth, this.props.renderwareHeight);
    context.clearRect(0, 0, this.props.renderwareWidth, this.props.renderwareHeight);
    
    // context.restore();
    // const context = this.canvasRef.current.getContext('2d');
    // const canvas = this.canvasRef.current;

    let startX = 0;
    // let startY = (canvas.height / 2) + (canvas.height / 4);
    let startY = window.innerHeight /2;
    let barHeightMultiplier = 1.2;

    for (var i=0; i < this.props.frequencyArray.length; i++)
    {
      context.save();
      context.beginPath();
      // context.fillStyle = "#fdff45";
      context.fillStyle = "#ffffd0";

      let frequencySample = this.props.frequencyArray[i];

      context.fillRect(startX, startY, this.barWidth, (128 - frequencySample) * barHeightMultiplier );

      // this.barAmplitudes[i] = -Math.abs( Math.sin(this.barAngles[i]) * 120 );
      // this.barAngles[i] += 0.04;
      startX += 4;
      // startX += (this.barWidth + this.barSpacing);
    }

    // console.log(this.props.frequencyArray)
    // this.setState(prevState => ({ angle: prevState.angle + 1 }));
    // this.raf = requestAnimationFrame(this.updateAnimation);
  }

  render()
  {
    return <canvas ref={this.canvasRef} width={this.props.renderwareWidth} height={this.props.renderwareHeight} />
  }
}
export default RTATypeB;