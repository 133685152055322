
import React from 'react';
import './PanelFilters.css';

import GENRE_TYPE from '../enums/GENRE_TYPE'
import BUTTON_TYPE from '../enums/BUTTON_TYPE'

import Row from 'react-bootstrap/Row';
import ButtonGenreFilter from './ButtonGenreFilter'
import CardTypeSwitcher from './CardTypeSwitcher'
import ButtonMain from './ButtonMain'

class PanelFilters extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      expanded: true,
    }

    this.handleFilterClick = this.handleFilterClick.bind(this);
  }

  componentDidMount()
  {
    // for (var i=0; i<GENRE_TYPE.properties.length; i++)
    // {
    //   console.log(this.filters[i])
    //   this.filters[i] = true;
    // }

    // console.log(this.filters)
  }

  componentDidUpdate()
  {
    // console.log(this.props.filters)
  }

  handleFilterClick(id, enabled)
  {
    this.props.handleFilterClick(id, enabled);
  }

  render()
  {
    return(
      <div className="panel-filters">

        {/* <div className="panel-filters-copper-list"> */}
        {/* </div> */}

        {/* <Row> */}
          <div className="panel-filters-container" >

            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.SOUNDTRACK]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.SOUNDTRACK} title={GENRE_TYPE.properties[GENRE_TYPE.SOUNDTRACK].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.ITALO_DISCO]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.ITALO_DISCO} title={GENRE_TYPE.properties[GENRE_TYPE.ITALO_DISCO].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.EUROBEAT]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.EUROBEAT} title={GENRE_TYPE.properties[GENRE_TYPE.EUROBEAT].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.DARK_ITALO]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.DARK_ITALO} title={GENRE_TYPE.properties[GENRE_TYPE.DARK_ITALO].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.DARK_EUROBEAT]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.DARK_EUROBEAT} title={GENRE_TYPE.properties[GENRE_TYPE.DARK_EUROBEAT].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.ELECTRO_ROCK]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.ELECTRO_ROCK} title={GENRE_TYPE.properties[GENRE_TYPE.ELECTRO_ROCK].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.SPYWAVE]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.SPYWAVE} title={GENRE_TYPE.properties[GENRE_TYPE.SPYWAVE].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.SPYFI]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.SPYFI} title={GENRE_TYPE.properties[GENRE_TYPE.SPYFI].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.CELESTIAL_BODY_MUSIC]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.CELESTIAL_BODY_MUSIC} title={GENRE_TYPE.properties[GENRE_TYPE.CELESTIAL_BODY_MUSIC].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.SID_COVERS]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.SID_COVERS} title={GENRE_TYPE.properties[GENRE_TYPE.SID_COVERS].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.EBM]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.EBM} title={GENRE_TYPE.properties[GENRE_TYPE.EBM].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.DARKWAVE]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.DARKWAVE} title={GENRE_TYPE.properties[GENRE_TYPE.DARKWAVE].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.DANCE]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.DANCE} title={GENRE_TYPE.properties[GENRE_TYPE.DANCE].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.DREAMPOP]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.DREAMPOP} title={GENRE_TYPE.properties[GENRE_TYPE.DREAMPOP].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.REMIX]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.REMIX} title={GENRE_TYPE.properties[GENRE_TYPE.REMIX].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.HOUSE]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.HOUSE} title={GENRE_TYPE.properties[GENRE_TYPE.HOUSE].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.EXPERIMENTAL]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.EXPERIMENTAL} title={GENRE_TYPE.properties[GENRE_TYPE.EXPERIMENTAL].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.ELECTRO]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.ELECTRO} title={GENRE_TYPE.properties[GENRE_TYPE.ELECTRO].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.POP]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.POP} title={GENRE_TYPE.properties[GENRE_TYPE.POP].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.SYNTHPOP]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.SYNTHPOP} title={GENRE_TYPE.properties[GENRE_TYPE.SYNTHPOP].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.COVER]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.COVER} title={GENRE_TYPE.properties[GENRE_TYPE.COVER].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.INDUSTRIAL]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.INDUSTRIAL} title={GENRE_TYPE.properties[GENRE_TYPE.INDUSTRIAL].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.HARD_RAGE]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.HARD_RAGE} title={GENRE_TYPE.properties[GENRE_TYPE.HARD_RAGE].displayName} />
            <ButtonGenreFilter enabled={!this.props.filters[GENRE_TYPE.BASS_JAMMIN]} handleFilterClick={this.handleFilterClick} id={GENRE_TYPE.BASS_JAMMIN} title={GENRE_TYPE.properties[GENRE_TYPE.BASS_JAMMIN].displayName} />

            {/* <ButtonGenreFilter onClick={this.handleFilterClick} id={GENRE_TYPE.RESERVED_1} title={GENRE_TYPE.properties[GENRE_TYPE.RESERVED_1].displayName} /> */}
            {/* <ButtonGenreFilter onClick={this.handleFilterClick} id={GENRE_TYPE.RESERVED_2} title={GENRE_TYPE.properties[GENRE_TYPE.RESERVED_2].displayName} /> */}
            {/* <ButtonGenreFilter onClick={this.handleFilterClick} id={GENRE_TYPE.RESERVED_3} title={GENRE_TYPE.properties[GENRE_TYPE.RESERVED_3].displayName} /> */}
            {/* <ButtonGenreFilter onClick={this.handleFilterClick} id={GENRE_TYPE.RESERVED_4} title={GENRE_TYPE.properties[GENRE_TYPE.RESERVED_4].displayName} /> */}

          </div>

        {/* </Row> */}

        {/* <CardTypeSwitcher /> */}

        {/* <div className="panel-filters-logo">
          LOGO
        </div> */}

        <div className="panel-filters-count unselectable">
          Filtered: {this.props.songsFiltered} / Total: {this.props.songsTotal}
        </div>

        <div className="panel-filters-buttons">
          <ButtonMain type={BUTTON_TYPE.SHOW_ALL} onClick={this.props.handleFiltersShowAll} />
          <ButtonMain type={BUTTON_TYPE.HIDE_ALL} onClick={this.props.handleFiltersHideAll} />
          <ButtonMain type={BUTTON_TYPE.SHOW_ONE} onClick={this.props.handleFiltersShowOne} disabled={true} />
          <ButtonMain type={BUTTON_TYPE.EXIT} onClick={this.props.handleClose} />
        </div>

      </div>
    );
  }
}

export default PanelFilters;