
import React from 'react';
import './ButtonGenreFilter.css';

import { FaFireExtinguisher } from 'react-icons/fa';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { FaRocket } from 'react-icons/fa';

import GENRE_TYPE from '../enums/GENRE_TYPE'

class ButtonGenreFilter extends React.Component
{
  constructor()
  {
    super();

    this.state = 
    {
    }
  }

  componentDidMount()
  {
  }

  componentDidUpdate()
  {
  }

  // handleClick()
  // {
  //   this.props.handleClick(this.props.enabled)

  //   // if (this.props.enabled)
  //   // {
  //   //   this.setState({ enabled: false });
  //   // }
  //   // else
  //   // {
  //   //   this.setState({ enabled: true });
  //   // }

  //   // this.props.onClick(this.state.id, this.props.enabled);
  // }

  // Obsolete

  // renderIcon()
  // {
  //   switch (this.props.title)
  //   {
  //     case "Soundtrack": return(<FaUmbrellaBeach />); break;
  //     case "Italo Disco": return(<FaUmbrellaBeach />); break;
  //     case "Eurobeat": return(<FaFireExtinguisher />); break;
  //     case "Dark Italo": return(<FaUmbrellaBeach />); break;
  //     case "Dark Eurobeat": return(<FaFireExtinguisher />); break;
  //     case "Electro Rock": return(<FaUmbrellaBeach />); break;
  //     case "SpyWave": return(<FaUmbrellaBeach />); break;
  //     case "Spy-Fi": return(<FaUmbrellaBeach />); break;
  //     case "Celestial Body Music": return(<FaRocket />); break;

  //     default: return("NOP");
  //   }
  // }

  renderFilterImage()
  {
    switch (this.props.id)
    {
      case GENRE_TYPE.SOUNDTRACK: return(<img src={"img/filters/filter-image-soundtrack.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.ITALO_DISCO: return(<img src={"img/filters/filter-image-italo-disco.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.EUROBEAT: return(<img src={"img/filters/filter-image-eurobeat.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.DARK_ITALO: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.DARK_EUROBEAT: return(<img src={"img/filters/filter-image-dark-eurobeat.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.ELECTRO_ROCK: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.SPYWAVE: return(<img src={"img/filters/filter-image-spywave.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.SPYFI: return(<img src={"img/filters/filter-image-spy-fi.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.CELESTIAL_BODY_MUSIC: return(<img src={"img/filters/filter-image-cbm.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.SID_COVERS: return(<img src={"img/filters/filter-image-sid-covers.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.EBM: return(<img src={"img/filters/filter-image-ebm.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.DARKWAVE: return(<img src={"img/filters/filter-image-darkwave.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.DANCE: return(<img src={"img/filters/filter-image-dance.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.DREAMPOP: return(<img src={"img/filters/filter-image-dreampop.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.REMIX: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.HOUSE: return(<img src={"img/filters/filter-image-house.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.EXPERIMENTAL: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.ELECTRO: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.POP: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.SYNTHPOP: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.COVER: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.INDUSTRIAL: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.HARD_RAGE: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;
      case GENRE_TYPE.BASS_JAMMIN: return(<img src={"img/filters/filter-image-empty.jpg"} alt="filterImage" />); break;

      default: return("NOP");
    }
  }

  render()
  {
    return(
      <div className="button-genre-filter spacing-bottom spacing-left highlight" onClick={() => this.props.handleFilterClick(this.props.id, this.props.enabled)} >

        {this.props.enabled && (
        <div className="button-genre-filter-led" id={"button-genre-filter-" + this.props.id} >
        </div>
        )}

        {!this.props.enabled && (
        <div className="button-genre-filter-led-disabled" id={"button-genre-filter-" + this.props.id} >
        </div>
        )}

        <div className="button-genre-filter-title unselectable">
          {this.props.title}
        </div>

        {this.props.enabled && (
        <div className="button-genre-filter-image unselectable">
          {this.renderFilterImage()}
        </div>
        )}

        {!this.props.enabled && (
        <div className="button-genre-filter-image-disabled unselectable">
          {this.renderFilterImage()}
        </div>
        )}

      </div>
    );
  }
}

export default ButtonGenreFilter;