
// ***********************************************************************
// ** Player TimeDisplay
// ***********************************************************************

import React from 'react';

class TimeDisplay extends React.Component
{
  constructor(props)
  {
    super(props);

    this.canvasRef = React.createRef();

    this.state = 
    {
      dummy: 0,
    }

    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.frames = 0;

    this.formattedTime = "";

    this.updateAnimation = this.updateAnimation.bind(this);
  }

  componentDidMount()
  {
    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  componentWillUnmount()
  {
    cancelAnimationFrame(this.raf);
  }

  addPaddingZeroes(value, padding)
  {
    let result = "";

    if (value > 9)
    {
      result = "" + value;
    }
    else
    {
      result = "0" + value;
    }

    return result;
  }

  //  var hours   = Math.floor(sec_num / 3600);
  //   var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  //   var seconds = sec_num - (hours * 3600) - (minutes * 60);

  updateAnimation()
  {
    this.frames ++;
    if (this.frames == 60)
    {
      this.frames = 0;
    }

    this.hours = Math.floor(this.props.currentTime / 3600);
    this.minutes = Math.floor((this.props.currentTime - (this.hours * 3600)) / 60);
    this.seconds = this.props.currentTime - (this.hours * 3600) - (this.minutes * 60);

    this.formattedTime = ""; 
    this.formattedTime += this.addPaddingZeroes(this.hours, 2) + ":";
    this.formattedTime += this.addPaddingZeroes(this.minutes, 2) + ":";
    this.formattedTime += this.addPaddingZeroes(this.seconds, 2) + ":";
    this.formattedTime += this.addPaddingZeroes(this.frames, 2);

    // console.log("this.props.audio.currentTime>", this.props.audio.currentTime);
    // console.log("this.props.audio>", this.props.audio);

    // console.log("Time Display UPDATE>", this.formattedTime);
    document.getElementById("panel-player-time-display-clock").innerHTML = "SMPTE " + this.formattedTime;

    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  componentDidUpdate()
  {
    // console.log("componentDidUpdate");
  }

  render()
  {
    return <div className="panel-player-time-display-clock unselectable" id="panel-player-time-display-clock">
    </div> 
  }
}
export default TimeDisplay;