
import React from 'react';
import './PanelTop.css';

import ButtonMain from './ButtonMain'
import BUTTON_TYPE from '../enums/BUTTON_TYPE';

class PanelTop extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      expanded: true,
    }

    this.handleFilterClick = this.handleFilterClick.bind(this);
  }

  handleFilterClick(id, enabled)
  {
    this.props.handleFilterClick(id, enabled);
  }

  getGenreFormatted()
  {
    let result = "";
    for (var i=0; i<this.props.songData.genre.length; i++)
    {
      result += this.props.songData.genre[i] + " / ";
    }
    result = result.substring(0, result.length - 2);
    return result;
  }

  render()
  {
    return(
      <div className="panel-top">

        <div className="panel-top-copper-list">
        </div>

        <div className="panel-top-album-art">
          <img src={this.props.imgSrc} className="album-art" alt="albumArt" />
        </div>

        <div className="panel-top-labels">
          <div className="panel-top-row">Title:</div>
          <div className="panel-top-row">Artist:</div>
          <div className="panel-top-row">Song:</div>
          <div className="panel-top-row">Album:</div>
          <div className="panel-top-row">Written By:</div>
          <div className="panel-top-row">Year:</div>
          <div className="panel-top-row">Genre:</div>
          <div className="panel-top-row">Song DOB:</div>
          <div className="panel-top-row">Technology:</div>
          <div className="panel-top-row">Cat Number:</div>
          {/* <div className="panel-top-row">Liner Notes:</div> */}
        </div>

        <div className="panel-top-info">
          <div className="panel-top-row">{this.props.songData.title}</div>
          <div className="panel-top-row">{this.props.songData.artist}</div>
          <div className="panel-top-row">{this.props.songData.song}</div>
          <div className="panel-top-row">{this.props.songData.album}</div>
          <div className="panel-top-row">{this.props.songData.writtenBy}</div>
          <div className="panel-top-row">{this.props.songData.year}</div>
          <div className="panel-top-row">{this.getGenreFormatted()}</div>
          <div className="panel-top-row">{this.props.songData.songDOB}</div>
          <div className="panel-top-row">{this.props.songData.technology}</div>
          <div className="panel-top-row">{this.props.songData.catalogueNumber}</div>
          {/* <div className="panel-top-row">{this.props.songData.linerNotes}</div> */}
        </div>

        {/* <CardTypeSwitcher /> */}

        <div className="panel-top-logo">
          music.insaneeyes.com
        </div>

        <div className="panel-top-buttons">
          <ButtonMain type={BUTTON_TYPE.SETTINGS} onClick={this.props.handlePanelSettingsOpen} />
          <ButtonMain type={BUTTON_TYPE.FILTERS} onClick={this.props.handlePanelFiltersOpen}  />
          <ButtonMain type={BUTTON_TYPE.CREDITS} onClick={this.props.handleJumpToCredits} />
          <ButtonMain type={BUTTON_TYPE.LYRICS} onClick={this.props.handleJumpToLyrics} />
          <ButtonMain type={BUTTON_TYPE.GEAR} onClick={this.props.handleJumpToGear} />
          <ButtonMain type={BUTTON_TYPE.FAVORITES} onClick={this.props.handleJumpToFavorites} />
          <ButtonMain type={BUTTON_TYPE.EXIT} onClick={this.props.handleExitApp} />
        </div> 

      </div>
    );
  }
}

export default PanelTop;