
import React from 'react';
import './PanelError.css';
import ButtonScaler from './ButtonScaler';

class PanelError extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      expanded: true,
    }
  }

  handleClose()
  {
    this.props.handleClose();
  }

  render()
  {
    return(
      <div className="panel-error">
        <div className="panel-error-container">
            <div className="panel-error-title-bar noselect">
              <div className="panel-error-title-bar-label noselect">
              </div>
            </div>

            <ButtonScaler x={window.innerWidth/2 - 80} y={24} width={160} height={64} title="Close Anyway & Proceed" icon="Close" align="center" onClick={this.props.handleClose} />

            <div className="panel-error-message">
              {this.props.errorMessage}
            </div>

        </div>
      </div>
    );
  }
}

export default PanelError;