
import React from 'react';
import './PanelBottom.css';
import Row from 'react-bootstrap/Row';
import ButtonGenreFilter from './ButtonGenreFilter'

class PanelBottom extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      expanded: true,
    }
  }

  render()
  {
    return(
      <div className="panel-bottom">

      </div>
    );
  }
}

export default PanelBottom;