
var GENRE_TYPE = 
{
  SOUNDTRACK : 1,
  ITALO_DISCO : 2,
  EUROBEAT : 3,
  DARK_ITALO : 4,
  DARK_EUROBEAT : 5,
  ELECTRO_ROCK : 6,
  SPYWAVE : 7,
  SPYFI : 8,
  CELESTIAL_BODY_MUSIC : 9,
  SID_COVERS : 10,
  EBM : 11,
  DARKWAVE : 12,
  DANCE : 13,
  DREAMPOP : 14,
  REMIX : 15,
  HOUSE : 16,
  EXPERIMENTAL : 17,
  ELECTRO : 18,
  POP : 19,
  SYNTHPOP : 20,
  COVER : 21,
  INDUSTRIAL : 22,
  HARD_RAGE : 23,
  BASS_JAMMIN : 24,

  // 1 : {name: "SOUNDTRACK", displayName: "stracs"},

  properties:
  {
    1 : {name: "SOUNDTRACK", displayName: "Soundtrack" },
    2 : {name: "ITALO_DISCO", displayName: "Italo Disco" },
    3 : {name: "EUROBEAT", displayName: "Eurobeat" },
    4 : {name: "DARK_ITALO", displayName: "Dark Italo" },
    5 : {name: "DARK_EUROBEAT", displayName: "Dark Eurobeat" },
    6 : {name: "ELECTRO_ROCK", displayName: "Electro Rock" },
    7 : {name: "SPYWAVE", displayName: "SpyWave" },
    8 : {name: "SPYFI", displayName: "Spy-Fi" },
    9 : {name: "CELESTIAL_BODY_MUSIC", displayName: "Celestial Body Music" },
    10 : {name: "SID_COVERS", displayName: "SID Covers" },
    11 : {name: "EBM", displayName: "EBM" },
    12 : {name: "DARKWAVE", displayName: "DarkWave" },
    13 : {name: "DANCE", displayName: "Dance" },
    14 : {name: "DREAMPOP", displayName: "Dreampop" },
    15 : {name: "REMIX", displayName: "Remix" },
    16 : {name: "HOUSE", displayName: "House" },
    17 : {name: "EXPERIMENTAL", displayName: "Experimental" },
    18 : {name: "ELECTRO", displayName: "Electro" },
    19 : {name: "POP", displayName: "Pop" },
    20 : {name: "SYNTHPOP", displayName: "Synthpop" },
    21 : {name: "COVER", displayName: "Cover" },
    22 : {name: "INDUSTRIAL", displayName: "Industrial" },
    23 : {name: "HARD_RAGE", displayName: "Hard Rage" },
    24 : {name: "BASS_JAMMIN", displayName: "Bass Jammin" },
  }
}

export default GENRE_TYPE;