
import React from 'react';
import './CardTypeSwitcher.css';

import { FaFireExtinguisher } from 'react-icons/fa';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { FaRocket } from 'react-icons/fa';

class CardTypeSwitcher extends React.Component
{
  constructor()
  {
    super();

    this.state = 
    {
    }
  }

  renderIcon()
  {
    switch (this.props.title)
    {
      case "Soundtrack": return(<FaUmbrellaBeach />); break;
      case "Italo Disco": return(<FaUmbrellaBeach />); break;
      case "Eurobeat": return(<FaFireExtinguisher />); break;
      case "Dark Italo": return(<FaUmbrellaBeach />); break;
      case "Dark Eurobeat": return(<FaFireExtinguisher />); break;
      case "Electro Rock": return(<FaUmbrellaBeach />); break;
      case "SpyWave": return(<FaUmbrellaBeach />); break;
      case "Spy-Fi": return(<FaUmbrellaBeach />); break;
      case "Celestial Body Music": return(<FaRocket />); break;

      default: return("");
    }
  }

  render()
  {
    return(
      <div className="card-type-switcher-container">
        {/* {this.renderIcon()} */}
        {this.props.title}
      </div>
    );
  }
}

export default CardTypeSwitcher;