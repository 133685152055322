
// ***********************************************************************
// ** Masterminder
// ** Real Time Analyzer
// ***********************************************************************

import React from 'react';

class RTA extends React.Component
{
  constructor(props)
  {
    super(props);

    this.canvasRef = React.createRef();

    this.state = 
    {
      angle: 0,
    }

    this.updateAnimation = this.updateAnimation.bind(this);

    this.barCurrentAngle = 0;
    this.barSine = 0;
    this.numberOfBars = 30;

    this.barAmplitudes = [];
    this.barAngles = [];

    this.barWidth = 12;
    this.barMaxHeight = 320;
    this.barSpacing = 8;

    let initialAmplitude = 1;
    for (var i=0; i<this.numberOfBars; i++)
    {
      this.barAmplitudes[i] = initialAmplitude;
      initialAmplitude += 6;
    }

    let initialAngle = 0;
    for (var i=0; i<this.numberOfBars; i++)
    {
      this.barAngles[i] = initialAngle;
      initialAngle += 0.72;
    }
  }

  componentDidMount()
  {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0,0,canvas.width, canvas.height);
    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  componentWillUnmount()
  {
    cancelAnimationFrame(this.raf);
  }

  updateAnimation()
  {
    this.setState(prevState => ({ angle: prevState.angle + 1 }));
    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  updateFrequencyBars()
  {
    const context = this.canvasRef.current.getContext('2d');

    let startX = 0;
    let startY = 295;

    for (var i=0; i < this.numberOfBars; i++)
    {
      context.save();
      context.beginPath();
      // context.fillStyle = "#fdff45";
      context.fillStyle = "#ffffd0";
      context.fillRect(startX, startY, this.barWidth, this.barAmplitudes[i]);

      this.barAmplitudes[i] = -Math.abs( Math.sin(this.barAngles[i]) * 120 );
      this.barAngles[i] += 0.04;

      startX += (this.barWidth + this.barSpacing);
    }
  }

  componentDidUpdate()
  {
    // console.log("componentDidUpdate");

    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');

    const angle = this.state.angle * 1;

    context.save();
    context.beginPath();
    context.clearRect(0,0,canvas.width, canvas.height);
    context.translate(canvas.width/2, canvas.height/2);
    context.rotate((angle * Math.PI) / 180);
    context.fillStyle = "rgba(0,0,0,0.24)";
    context.fillRect(-canvas.width/4, -canvas.height/4, canvas.width/2, canvas.height/2);
    context.restore();

    this.updateFrequencyBars();
  }

  render()
  {
    return <canvas 
              ref={this.canvasRef} 
              width={900} 
              height={400}
            />
  }
}
export default RTA;