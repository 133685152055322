
import React from 'react';
import './PanelMain.css';

import { Scrollbars } from 'react-custom-scrollbars';

import SongCard from './SongCard'
import SongCardRow from './SongCardRow'
import SongCardBig from './SongCardBig'

import ButtonCategory from './ButtonCategory'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class PanelMain extends React.Component
{
  constructor(props)
  {
    super(props);

    this.CARD_TYPES = 
    {
      SMALL_CARD : 1,
      ROW_CARD :2,
      BIG_CARD : 3
    }

    this.state = 
    {
      expanded: true,
    }

    // this.cardType = this.CARD_TYPES.SMALL_CARD;
    this.cardType = this.CARD_TYPES.BIG_CARD;

    this.handleSongCardOnClick = this.handleSongCardOnClick.bind(this);
    this.handleSongCardMouseOver = this.handleSongCardMouseOver.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.renderThumbVertical = this.renderThumbVertical.bind(this);
  }

  handleSongCardOnClick(songData)
  {
    this.props.handleSongCardClick(songData);
  }

  handleSongCardMouseOver(songData)
  {
    this.props.handleSongCardMouseOver(songData);
  }

  renderSongCards()
  {
    let songCards = null;
    
    switch (this.props.cardType)
    {
      case this.CARD_TYPES.SMALL_CARD: songCards = this.renderSongCardsTypeA(); break;
      case this.CARD_TYPES.ROW_CARD: songCards = this.renderSongCardsTypeB(); break;
      case this.CARD_TYPES.BIG_CARD: songCards = this.renderSongCardsTypeC(); break;
    }

    return songCards;
  }

  renderSongCardsTypeA()
  {
    let songs = this.props.songs;
    let cards = [];
    // console.log("this.CARD_TYPE", this.cardType);

    for (var i=1; i<songs.length; i++)
    {
      if (songs[i].visible === false)
      {
      }
      else
      {
        let card = (
    
          // 540px	720px	  960px	  1140px
          // sm   	md      lg      xl
          <Col key={i} sm={12} md={6} lg={6} xl={4} >
    
            <SongCard
              songData={songs[i]}
              handleMouseOver={this.handleSongCardMouseOver}
              handleOnClick={this.handleSongCardOnClick}
            />

          </Col>
        )
        cards.push(card);
      }  
    }

    return cards;
  }

  renderSongCardsTypeB()
  {
    let songs = this.props.songs;
    let cards = [];

    for (var i=1; i<songs.length; i++)
    {
      if (songs[i].visible === false)
      {
      }
      else
      {
        let card = (
    
          // 540px	720px	  960px	  1140px
          // sm   	md      lg      xl
          // <Col sm={12} md={6} lg={6} xl={4} >
          <Col sm={12} md={12} lg={12} xl={12} >
    
            <SongCardRow
              songData={songs[i]}
              handleMouseOver={this.handleSongCardMouseOver}
              handleOnClick={this.handleSongCardOnClick}
            />

          </Col>
        )
        cards.push(card);
      }
    }

    return cards;
  }

  renderSongCardsTypeC()
  {
    let songs = this.props.songs;
    let cards = [];

    for (var i=1; i<songs.length; i++)
    {
      if (songs[i].visible === false)
      {
      }
      else
      {
        let card = (
    
          // 540px	720px	  960px	  1140px
          // sm   	md      lg      xl
          // <Col sm={12} md={6} lg={6} xl={4} >
          <Col sm={6} md={4} lg={4} xl={2} >
    
            <SongCardBig
              songData={songs[i]}
              handleMouseOver={this.handleSongCardMouseOver}
              handleOnClick={this.handleSongCardOnClick}
            />

          </Col>
        )
        cards.push(card);
      }
    }

    return cards;
  }

  renderThumb({ style, ...props }) 
  {
    const { top } = this.state;
    const thumbStyle = 
    {
      backgroundColor: "#444a4e",
      width: "24px",
      borderRadius: "1px",
    };
    return (
        <div style={{ ...style, ...thumbStyle }} {...props}/>
    );
  }

  renderThumbVertical({ style, ...props }) 
  {
    const { top } = this.state;
    const thumbStyle = 
    {
      backgroundColor: "#666a6e",
      width: "56px",
      borderRadius: "1px",
    };
    return (
        <div style={{ ...style, width: '60px', backgroundColor: "#666a6e" }} {...props}/>
    );
  }

  render()
  {
    return(
      <div className="panel-main">
        <div className="panel-main-container">

            <Scrollbars
                // style={{ width, height }}
                // onScroll={this.handleScroll}
                // renderThumbHorizontal={this.renderThumb}
                // renderThumbVertical={this.renderThumbVertical}

                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                renderView={props => <div {...props} className="view"/>}>

              <Row className="song-cards-container">
                {this.renderSongCards()}
              </Row>

              {/* <Col xs={1}>
                <div className="panel-song-side" >
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                  <ButtonCategory title="TEST"/>
                </div>          
              </Col> */}

            </Scrollbars>

        </div>
      </div>
    );
  }
}

export default PanelMain;

// {
//   "track" : "./songs/nop.mp3",
//   "title" : "Sahasrabhuja",
//   "albumArt" : "img/sahasrabhuja-2-front.jpg",
//   "artist" : "Sahasrabhuja",
//   "song" : "222",
//   "album" : "2",
//   "writtenBy" : "...",
//   "year" : "NOP",
//   "genre" : ["CBM (Celestial Body Music)"],
//   "songDOB" : "NOP",
//   "youtube" : false,
//   "rating" : 4,
//   "catalogueNumber" : "KAT-SVA-000",
//   "finalizer" : null
// },

  // {
  //   "track" : "./songs/10.000 Govorancijea.mp3",
  //   "title" : "10.000 Govorancijea",
  //   "albumArt" : "./img/hyperswas-type-A.jpg",
  //   "artist" : "Sahasrabhuja",
  //   "song" : "Govorancijea",
  //   "album" : "III",
  //   "writtenBy" : "Dj",
  //   "year" : "2015",
  //   "genre" : ["CBM (Celestial Body Music)", "Sutra", "Boodizam"],
  //   "songDOB" : "05 ‎October ‎2015, ‏‎15:33:36",
  //   "youtube" : false,
  //   "youtubeLink" : "",
  //   "rating" : 1,
  //   "catalogueNumber" : "KAT-SVA-088",
  //   "technology" : "",
  //   "linerNotes" : "",
  //   "finalizer" : null
  // }



// [
//   {
//     "track" : "./songs/nop.mp3",
//     "title" : "...",
//     "albumArt" : "./img/album-art.png",
//     "artist" : "...",
//     "song" : "",
//     "album" : "...",
//     "writtenBy" : "...",
//     "year" : "NOP",
//     "genre" : ["..."],
//     "songDOB" : "NOP",
//     "youtube" : false,
//     "youtubeLink" : "",
//     "rating" : 3,
//     "catalogueNumber" : "KAT-GNR-000",
//     "linerNotes" : "",
//     "finalizer" : null
//   },
// ]



