
import React from 'react';
import './ButtonMain.css';

import { GiExitDoor } from 'react-icons/gi';
import { FaYoutube } from 'react-icons/fa';
import { FaFireExtinguisher } from 'react-icons/fa';
import { FaRocket } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';

import { FaUmbrellaBeach } from 'react-icons/fa';
import { FaWindowClose } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { GiCardJoker } from 'react-icons/gi';
import { FaFilter } from 'react-icons/fa';
import { FaItunesNote } from 'react-icons/fa';
import { FaBatteryFull } from 'react-icons/fa';
import { FaBatteryEmpty } from 'react-icons/fa';
import { FaBatteryQuarter } from 'react-icons/fa';
import { IoMdColorFill } from 'react-icons/io';
import { FaHandsHelping } from 'react-icons/fa';
import { FaServer } from 'react-icons/fa';
import { MdFavorite } from 'react-icons/md';

import BUTTON_TYPE from '../enums/BUTTON_TYPE';

class ButtonMain extends React.Component
{
  constructor()
  {
    super();
    this.state = 
    {
    }
  }

  renderExpandedMode()
  {
  }

  renderIcon()
  {
    switch (this.props.type)
    {
      case BUTTON_TYPE.SETTINGS: return (<IoMdSettings />); break;
      case BUTTON_TYPE.FILTERS: return (<FaFilter />); break;
      case BUTTON_TYPE.LYRICS: return (<FaItunesNote />); break;
      case BUTTON_TYPE.SHOW_ALL: return (<FaBatteryFull />); break;
      case BUTTON_TYPE.HIDE_ALL: return (<FaBatteryEmpty />); break;
      case BUTTON_TYPE.SHOW_ONE: return (<FaBatteryQuarter />); break;
      case BUTTON_TYPE.EXIT: return (<FaWindowClose />); break;
      case BUTTON_TYPE.CARD_TYPE_A: return (<GiCardJoker />); break;
      case BUTTON_TYPE.CARD_TYPE_B: return (<GiCardJoker />); break;
      case BUTTON_TYPE.CARD_TYPE_C: return (<GiCardJoker />); break;
      case BUTTON_TYPE.COLOR_SCHEME_A: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_B: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_C: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_D: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_E: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_F: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_G: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_H: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_I: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_J: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_K: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_L: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_M: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_N: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.COLOR_SCHEME_O: return (<IoMdColorFill />); break;
      case BUTTON_TYPE.CREDITS: return (<FaHandsHelping />); break;
      case BUTTON_TYPE.GEAR: return (<FaServer />); break;
      case BUTTON_TYPE.FAVORITES: return (<MdFavorite />); break;

      default: return(<FaUmbrellaBeach />);
    }
  }

  renderTitle()
  {
    switch (this.props.type)
    {
      case BUTTON_TYPE.SETTINGS: return ("Settings"); break;
      case BUTTON_TYPE.FILTERS: return ("Filters"); break;
      case BUTTON_TYPE.CARD: return ("Card"); break;
      case BUTTON_TYPE.LYRICS: return ("Lyrics"); break;
      case BUTTON_TYPE.SHOW_ALL: return ("Show All"); break;
      case BUTTON_TYPE.HIDE_ALL: return ("Hide All"); break;
      case BUTTON_TYPE.SHOW_ONE: return ("Show One"); break;
      case BUTTON_TYPE.EXIT: return ("Exit"); break;
      case BUTTON_TYPE.CARD_TYPE_A: return ("CardTypeA"); break;
      case BUTTON_TYPE.CARD_TYPE_B: return ("CardTypeB"); break;
      case BUTTON_TYPE.CARD_TYPE_C: return ("CardTypeC"); break;
      case BUTTON_TYPE.COLOR_SCHEME_A: return ("Scheme A"); break;
      case BUTTON_TYPE.COLOR_SCHEME_B: return ("Scheme B"); break;
      case BUTTON_TYPE.COLOR_SCHEME_C: return ("Scheme C"); break;
      case BUTTON_TYPE.COLOR_SCHEME_D: return ("Scheme D"); break;
      case BUTTON_TYPE.COLOR_SCHEME_E: return ("Scheme E"); break;
      case BUTTON_TYPE.COLOR_SCHEME_F: return ("Scheme F"); break;
      case BUTTON_TYPE.COLOR_SCHEME_G: return ("Scheme G"); break;
      case BUTTON_TYPE.COLOR_SCHEME_H: return ("Scheme H"); break;
      case BUTTON_TYPE.COLOR_SCHEME_I: return ("Scheme I"); break;
      case BUTTON_TYPE.COLOR_SCHEME_J: return ("Scheme J"); break;
      case BUTTON_TYPE.COLOR_SCHEME_K: return ("Scheme K"); break;
      case BUTTON_TYPE.COLOR_SCHEME_L: return ("Scheme L"); break;
      case BUTTON_TYPE.COLOR_SCHEME_M: return ("Scheme M"); break;
      case BUTTON_TYPE.COLOR_SCHEME_N: return ("Scheme N"); break;
      case BUTTON_TYPE.COLOR_SCHEME_O: return ("Scheme O"); break;
      case BUTTON_TYPE.CREDITS: return ("Credits"); break;
      case BUTTON_TYPE.GEAR: return ("Gear"); break;
      case BUTTON_TYPE.FAVORITES: return ("Favorites"); break;
    }
  }

  render()
  {
    if (this.props.disabled)
    {
      return(
        <div className="button-main-disabled" >

          <div className="button-main-icon">
            {this.renderIcon()}
          </div>

          <div className="button-main-title">
            {this.renderTitle()}
          </div>

        </div>
      );
    }
    else
    {
      return(
        <div className="button-main" onClick={this.props.onClick} >

          <div className="button-main-icon">
            {this.renderIcon()}
          </div>

          <div className="button-main-title">
            {this.renderTitle()}
          </div>

        </div>
      );
    }
  }
}

export default ButtonMain;