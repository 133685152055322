
var BUTTON_TYPE = 
{
  SETTINGS : 1,
  FILTERS : 2,
  EXIT : 3,
  LYRICS : 4,
  SHOW_ALL : 5,
  HIDE_ALL : 6,
  SHOW_ONE : 7,
  CARD_TYPE_A : 8,
  CARD_TYPE_B : 9,
  CARD_TYPE_C : 10,
  COLOR_SCHEME_A : 11,
  COLOR_SCHEME_B : 12,
  COLOR_SCHEME_C : 13,
  COLOR_SCHEME_D : 14,
  COLOR_SCHEME_E : 15,
  COLOR_SCHEME_F : 16,
  COLOR_SCHEME_G : 17,
  COLOR_SCHEME_H : 18,
  COLOR_SCHEME_I : 19,
  COLOR_SCHEME_J : 20,
  COLOR_SCHEME_K : 21,
  COLOR_SCHEME_L : 22,
  COLOR_SCHEME_M : 23,
  COLOR_SCHEME_N : 24,
  COLOR_SCHEME_O : 25,
  CREDITS : 26,
  GEAR : 27,
  FAVORITES : 28,

  properties:
  {
    // 1 : {name: "SETTINGS", displayName: "SETTINGS"},
  }
}

export default BUTTON_TYPE;