
// ***********************************************************************
// ** Song Player Main
// ***********************************************************************

import React from 'react';
import './PanelPlayer.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RTA from './RTA';
import RTATypeB from './RTATypeB';
import Scrubber from './Scrubber';
import TimeDisplay from './TimeDisplay';
import VolumeDisplay from './VolumeDisplay';

import { FaYoutube } from 'react-icons/fa';
import { IoIosStarOutline } from 'react-icons/io';
import { IoIosStar } from 'react-icons/io';
import { FaExpand } from 'react-icons/fa';
import { FaWindowClose } from 'react-icons/fa';
import { FaWindowRestore } from 'react-icons/fa';
import { FaVolumeMute } from 'react-icons/fa';
import { FaVolumeDown } from 'react-icons/fa';
import { FaVolumeUp } from 'react-icons/fa';
import { FaYinYang } from 'react-icons/fa';
import { FaItunesNote } from 'react-icons/fa';

class PanelPlayer extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      expanded: true,
      currentTime: 0,
      duration: 0,
      volume: 10, // 1 -10
    }

    this.audio = null;
    this.audioContext = null;
    this.source = null;
    this.analyzer = null;
    this.frequencyArray = null;

    this.panelPlayerClass = "panel-player"
    this.minimizeTooltipText = "Minimize Player"

    this.handleScrubberClick = this.handleScrubberClick.bind(this);
    this.update = this.update.bind(this);
  }

  handleScrubberClick(e)
  {
    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;

    var xPos = parseInt(x/10);
    console.log("xPos",xPos)

    let scrubberPos = document.getElementById("panel-player-scrubber").style.left;
    document.getElementById("panel-player-scrubber-box").style.left = x + "px";

    this.audio.currentTime = xPos;
  }

  playSong()
  {
    this.audio.play();
  }

  stopSong()
  {
    console.log("STOP")
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  fetchLyrics()
  {
    console.log("FETCH LYRICS");
    fetch('/tkgs.txt').then((r) => r.text()).then(text  =>
    {
      console.log(text);
    })  
  }

  update()
  {
    this.analyzer.getByteTimeDomainData(this.frequencyArray);
    this.raf = requestAnimationFrame(this.update);
  }

  componentDidMount()
  {
    this.audio = new Audio(this.props.songData.track);
    this.audio.crossOrigin = "anonymous";

    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.source = this.audioContext.createMediaElementSource(this.audio);
    this.analyzer = this.audioContext.createAnalyser();
    this.analyzer.fftSize = 2048;
    this.source.connect(this.analyzer);
    this.analyzer.connect(this.audioContext.destination);

    this.playSong();

    console.log("PLAY" + " " + this.props.songData.track);
    console.log("audio", this.audio)
    console.log("audioContext", this.audioContext)
    console.log("source", this.source)
    console.log("analyzer", this.analyzer)

    this.frequencyArray = new Uint8Array(this.analyzer.frequencyBinCount);

    this.interval = setInterval(() =>
    {
      this.setState({ currentTime: parseInt(this.audio.currentTime) });
      this.setState({ duration: parseInt(this.audio.duration) });
      this.setState({ state: this.state})
    }, 100);

    this.update();

    // this.intervalFTT = setInterval(() =>
    // {
    //   this.update();
    // }, 1000/60);
  }

  componentWillUnmount()
  {
    this.stopSong();
  }

  handleClose()
  {
    this.props.handleClose();
  }

  handleMinimize()
  {
    this.props.handleMinimize();
  }

  handleJumpToLyrics() { window.open("https://www.lyrics.insaneeyes.com", "_blank"); }
  handleOpenYoutube() { window.open(this.props.songData.youtubeLink, "_blank"); }

  handleVolumeDown()
  {
    if (this.state.volume > 0)
    {
      this.setState({ volume: this.state.volume - 1 }, function() { this.audio.volume = this.state.volume / 10; } )
    }
  }
  
  showTooltip(text)
  {
    document.getElementById("panel-player-tooltips").innerHTML = text + "&nbsp;&nbsp;";
    document.getElementById("panel-player-tooltips").style.visibility = "visible";
  }

  hideTooltip()
  {
    document.getElementById("panel-player-tooltips").innerHTML = "";
    document.getElementById("panel-player-tooltips").style.visibility = "hidden";
  }

  handleVolumeUp()
  {
    if (this.state.volume < 10)
    {
      this.setState({ volume: this.state.volume + 1 }, function() { this.audio.volume = this.state.volume / 10; } )
    }
  }

  handleVolumeMute()
  {
    this.setState({ volume: 0 }, function() { this.audio.volume = 0; } )
  }

  handleVolumeUnmute() { this.audio.volume = 1; }

  renderYouTube()
  {
    if (this.props.songData.youtube)
    {
      return(
        <div className="panel-player-button orange highlight" onClick={() => this.handleOpenYoutube()} > <FaYoutube /> </div>
      );
    }
    else
    {
      return(
        <div className="panel-player-button orange disabled" > <FaYoutube /> </div>
      );
    }
  }

  getGenreCombinedString()
  {
    let result = "";
    for (var i=0; i<this.props.songData.genre.length; i++)
    {
      result += this.props.songData.genre[i] + " / ";
    }
    result = result.substring(0, result.length - 2);
    return result
  }

  render()
  {
    if (this.props.minimized)
    { 
      this.panelPlayerClass = "panel-player-minimized";
      this.minimizeTooltipText = "Maximize Player";
    }
    else
    {
      this.panelPlayerClass = "panel-player"
      this.minimizeTooltipText = "Minimize Player";
    }

    return(
      <div className={this.panelPlayerClass} onClick={this.handleOnClick} >

        <audio id="player" crossOrigin="anonymous">
          Your browser does not support the audio element.
        </audio>

        <div className="panel-player-container">

          <div className="panel-player-bcg-image">
            <img 
              src={this.props.songData.albumArt} 
              style={{ objectFit: 'cover', display: 'block', width: '100%', height: '100%' }}
            />
          </div>

          <Row>
              PLAYER 
          </Row>

          <div className="panel-player-album-art-shadow">
            <img 
              src={this.props.songData.albumArt} 
              className="album-art" 
              alt="albumArt" 
              style={{ borderRadius: '8px', display: 'block', width: '100%', height: '100%' }}
            />
          </div>

          <div className="panel-player-album-art">
            <img 
              src={this.props.songData.albumArt} 
              className="album-art" 
              alt="albumArt" 
              style={{ borderRadius: '8px', display: 'block', width: '100%', height: '100%' }}
            />
          </div>

          <div className="panel-player-artist unselectable" >
            {this.props.songData.artist}
          </div>

          <div className="panel-player-song unselectable" >
            {this.props.songData.song}
          </div>

          <div className="panel-player-analyzer" id="panel-player-analyzer">
            {/* <RTA /> */}
            <RTATypeB
              audio={this.audio} 
              frequencyArray={this.frequencyArray}
              renderwareWidth={this.props.renderwareWidth}
              renderwareHeight={this.props.renderwareHeight}
            />
          </div> 

          <div className="panel-player-scrubber" id="panel-player-scrubber" onClick={this.handleScrubberClick}>
            <Scrubber currentTime={this.state.currentTime} duration={this.state.duration} />
          </div> 

          <div className="panel-player-time-display" id="panel-player-time-display">
            <TimeDisplay currentTime={this.state.currentTime} duration={this.state.duration} />
          </div> 

          <div className="panel-player-volume-display" id="panel-player-volume-display">
            <VolumeDisplay volume={this.state.volume} />
          </div> 

          <div className="panel-player-lyrics">
            <div src={this.props.songData.lyrics} />
          </div>

          {( !this.props.minimized &&
          <div className="panel-player-other-data">
            <div className="panel-player-other-data-labels">
              <div className="panel-player-other-data-row">Title</div>
              <div className="panel-player-other-data-row">Artist</div>
              <div className="panel-player-other-data-row">Song</div>
              <div className="panel-player-other-data-row">Album</div>
              <div className="panel-player-other-data-row">Written By</div>
              <div className="panel-player-other-data-row">Year</div>
              <div className="panel-player-other-data-row">Genre</div>
              <div className="panel-player-other-data-row">Song DOB</div>
              <div className="panel-player-other-data-row">Technology</div>
              <div className="panel-player-other-data-row">Cat Number</div>
              <div className="panel-player-other-data-row">Liner Notes</div>
            </div>
            <div className="panel-player-other-data-info">
              <div className="panel-player-other-data-row">{this.props.songData.title}</div>
              <div className="panel-player-other-data-row">{this.props.songData.artist}</div>
              <div className="panel-player-other-data-row">{this.props.songData.song}</div>
              <div className="panel-player-other-data-row">{this.props.songData.album}</div>
              <div className="panel-player-other-data-row">{this.props.songData.writtenBy}</div>
              <div className="panel-player-other-data-row">{this.props.songData.year}</div>
              <div className="panel-player-other-data-row">{this.getGenreCombinedString()}</div>
              <div className="panel-player-other-data-row">{this.props.songData.songDOB}</div>
              <div className="panel-player-other-data-row">{this.props.songData.technology}</div>
              <div className="panel-player-other-data-row">{this.props.songData.catalogueNumber}</div>
              <div className="panel-player-other-data-row">{this.props.songData.linerNotes}</div>
            </div>
          </div>
          )}

          <div className="panel-player-tooltips unselectable" id="panel-player-tooltips">
            TOOLTIP YO!
          </div>

          <div className="panel-player-buttons">
            <div className="panel-player-button highlight" onMouseOver={() => this.showTooltip("Close Player")} onMouseOut={() => this.hideTooltip()} onClick={() => this.handleClose()} > <FaWindowClose /> </div>
            <div className="panel-player-button highlight" onMouseOver={() => this.showTooltip(this.minimizeTooltipText)} onMouseOut={() => this.hideTooltip()} onClick={() => this.handleMinimize()} > <FaWindowRestore /> </div>
            {this.renderYouTube()}
            <div className="panel-player-button highlight" onMouseOver={() => this.showTooltip("Lyrics (Module)")} onMouseOut={() => this.hideTooltip()} onClick={() => this.handleJumpToLyrics()} > <FaItunesNote /> </div>
            <div className="panel-player-button highlight" onMouseOver={() => this.showTooltip("Flip Side")} onMouseOut={() => this.hideTooltip()} onClick={() => this.handleVolumeUp()} > <FaYinYang /> </div>
            <div className="panel-player-button highlight" onMouseOver={() => this.showTooltip("Volume Up")} onMouseOut={() => this.hideTooltip()} onClick={() => this.handleVolumeUp()} > <FaVolumeUp /> </div>
            <div className="panel-player-button highlight" onMouseOver={() => this.showTooltip("Volume Down")} onMouseOut={() => this.hideTooltip()} onClick={() => this.handleVolumeDown()} > <FaVolumeDown /> </div>
            <div className="panel-player-button highlight" onMouseOver={() => this.showTooltip("Volume Mute")} onMouseOut={() => this.hideTooltip()} onClick={() => this.handleVolumeMute()} > <FaVolumeMute /> </div>
          </div>

        </div>

      </div>
    );
  }
}

export default PanelPlayer;