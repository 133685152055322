
// ***********************************************************************
// MUSIC PLAYER MAIN APP
// ***********************************************************************

import React, { Component } from 'react';
import './App.css';

import GENRE_TYPE from './enums/GENRE_TYPE'

import {createStore, combineReducers, applyMiddleware, bindActionCreators} from 'redux'

import PanelTop from './comps/PanelTop'
import PanelMain from './comps/PanelMain'
import PanelPlayer from './comps/PanelPlayer';
import PanelBottom from './comps/PanelBottom';
import PanelSettings from './comps/PanelSettings'
import PanelFilters from './comps/PanelFilters';
import PanelError from './comps/PanelError'

import Container from 'react-bootstrap/Container';

// const greetingReducer = (state='', action) => 
// {
//   switch (action.type)
//   {
//     case 'SAY_HELLO' : return "Hello"
//     case 'SAY_GOODBYE' : return "Goodbye"
//   }
//   return state;
// }

const songs = require('./songs.json');

const INITIAL_STATE = 
{
  version: "1.0",
  showLogs: false,
  triggerUpdate: false,
  songsTotal: songs.length,
  songsFiltered: songs.length,
  expanded: true,
  songData: songs[2],
  panelPlayerIsOpened: false,
  panelPlayerIsMinimized: false,
  panelSettingsIsOpened: false,
  panelFiltersIsOpened: false,
  panelErrorIsOpened: false,
  cardType: 1,
  errorMessage: "",

  renderwareWidth: 0,
  renderwareHeight: 0,
  playfieldWidth: 0,
  playfieldHeight: 0,
  playfieldBrowserWidth: 0,
  playfieldBrowserHeight: 0,
  playfieldScaleX: 0,
  playfieldScaleY: 0,
  playfieldTop: 0,

  filters: [false, false, false, false, false, false, 
  false, false, false, false, false, false, 
  false, false, false, false, false, false, 
  false, false, false, false, false, false
  ]
}

class App extends Component
{
  constructor(props)
  {
    super(props);

    // this.filters = Array(GENRE_TYPE.properties.length).fill(true);

    this.state = INITIAL_STATE;

    this.handleSongCardClick = this.handleSongCardClick.bind(this);
    this.handleSongCardMouseOver = this.handleSongCardMouseOver.bind(this);
    this.handlePanelPlayerClick = this.handlePanelPlayerClick.bind(this);
    this.handlePanelPlayerClose = this.handlePanelPlayerClose.bind(this);
    this.handlePanelPlayerMinimize = this.handlePanelPlayerMinimize.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handlePanelSettingsClose = this.handlePanelSettingsClose.bind(this);
    this.handlePanelSettingsOpen = this.handlePanelSettingsOpen.bind(this);
    this.handlePanelFiltersClose = this.handlePanelFiltersClose.bind(this);
    this.handlePanelFiltersOpen = this.handlePanelFiltersOpen.bind(this);
    this.handlePanelErrorClose = this.handlePanelErrorClose.bind(this);
    this.handleSetCardTypeA = this.handleSetCardTypeA.bind(this);
    this.handleSetCardTypeB = this.handleSetCardTypeB.bind(this);
    this.handleSetCardTypeC = this.handleSetCardTypeC.bind(this);
    this.handleExitApp = this.handleExitApp.bind(this);
    this.handleJumpToLyrics = this.handleJumpToLyrics.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleFiltersShowAll = this.handleFiltersShowAll.bind(this);
    this.handleFiltersHideAll = this.handleFiltersHideAll.bind(this);
    this.handleFiltersShowOne = this.handleFiltersShowOne.bind(this);
  }

  componentDidMount()
  {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount()
  {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize()
  {
    this.setState({ renderwareWidth: window.innerWidth, renderwareHeight: window.innerHeight });
    this.setState({ playfieldBrowserWidth: window.innerWidth, playfieldBrowserHeight: window.innerHeight }, ()=>
    {
      // console.log("this.state.playfieldBrowserWidth", this.state.playfieldBrowserWidth);
      // console.log("this.state.playfieldBrowserHeight", this.state.playfieldBrowserHeight);
      if (this.state.playfieldBrowserWidth < 1000)
      {
        this.setState({ errorMessage: "Display resolutions lower than 1024 x 768 px (XGA) are not supported."});
        this.setState({ panelErrorIsOpened: true });
      }
      else
      {
        this.setState({ panelErrorIsOpened: false });
      }
    });
  }

  handlePanelPlayerClick() { this.setState({ panelPlayerIsOpened: false }); }
  handlePanelPlayerClose() { this.setState({ panelPlayerIsOpened: false, panelPlayerIsMinimized: false }); }
  handlePanelPlayerMinimize() { this.setState(prevState => ({ panelPlayerIsMinimized: !prevState.panelPlayerIsMinimized }))};
  handlePanelSettingsClose() { this.setState({ panelSettingsIsOpened: false }); }
  handlePanelSettingsOpen() { this.setState({ panelSettingsIsOpened: true }); }
  handlePanelFiltersClose() { this.setState({ panelFiltersIsOpened: false }); }
  handlePanelFiltersOpen() { this.setState({ panelFiltersIsOpened: true }); }
  handlePanelErrorClose() { this.setState({ panelErrorIsOpened: false }); }
  handleSetCardTypeA() { this.setState({ cardType: 1 }); }
  handleSetCardTypeB() { this.setState({ cardType: 2 }); }
  handleSetCardTypeC() { this.setState({ cardType: 3 }); }
  
  handleExitApp() { window.open("http://www.insaneeyes.com", "_self"); }
  handleJumpToLyrics() { window.open("https://www.lyrics.insaneeyes.com", "_blank"); }
  handleJumpToCredits() { window.open("https://www.lyrics.insaneeyes.com/credits.html", "_blank"); }
  handleJumpToGear() { window.open("https://music.insaneeyes.com/equipment/", "_blank"); }
  handleJumpToFavorites() { window.open("https://music.insaneeyes.com/favorites/Favorites.txt", "_blank"); }

  handleSongCardMouseOver(pSongData)
  {
    if (!this.state.panelPlayerIsMinimized)
    {
      this.setState({ songData: pSongData });
    }
  }

  handleSongCardClick(pSongData)
  {
    if (!this.state.panelPlayerIsMinimized)
    {
      this.setState({ songData: pSongData });
      this.setState(prevState => ({panelPlayerIsOpened: !prevState.panelPlayerIsOpened}));
    }
  }

  handleFiltersShowAll()
  {
    this.handleFilterClick(GENRE_TYPE.SOUNDTRACK, false);
    this.handleFilterClick(GENRE_TYPE.ITALO_DISCO, false);
    this.handleFilterClick(GENRE_TYPE.EUROBEAT, false);
    this.handleFilterClick(GENRE_TYPE.DARK_ITALO, false);
    this.handleFilterClick(GENRE_TYPE.DARK_EUROBEAT, false);
    this.handleFilterClick(GENRE_TYPE.ELECTRO_ROCK, false);
    this.handleFilterClick(GENRE_TYPE.SPYWAVE, false);
    this.handleFilterClick(GENRE_TYPE.SPYFI, false);
    this.handleFilterClick(GENRE_TYPE.CELESTIAL_BODY_MUSIC, false);
    this.handleFilterClick(GENRE_TYPE.SID_COVERS, false);
    this.handleFilterClick(GENRE_TYPE.EBM, false);
    this.handleFilterClick(GENRE_TYPE.DARKWAVE, false);
    this.handleFilterClick(GENRE_TYPE.DANCE, false);
    this.handleFilterClick(GENRE_TYPE.DREAMPOP, false);
    this.handleFilterClick(GENRE_TYPE.REMIX, false);
    this.handleFilterClick(GENRE_TYPE.HOUSE, false);
    this.handleFilterClick(GENRE_TYPE.EXPERIMENTAL, false);
    this.handleFilterClick(GENRE_TYPE.ELECTRO, false);
    this.handleFilterClick(GENRE_TYPE.POP, false);
    this.handleFilterClick(GENRE_TYPE.SYNTHPOP, false);
    this.handleFilterClick(GENRE_TYPE.COVER, false);
    this.handleFilterClick(GENRE_TYPE.INDUSTRIAL, false);
    this.handleFilterClick(GENRE_TYPE.HARD_RAGE, false);
    this.handleFilterClick(GENRE_TYPE.BASS_JAMMIN, false);
  }

  handleFiltersHideAll()
  {
    this.handleFilterClick(GENRE_TYPE.SOUNDTRACK, true);
    this.handleFilterClick(GENRE_TYPE.ITALO_DISCO, true);
    this.handleFilterClick(GENRE_TYPE.EUROBEAT, true);
    this.handleFilterClick(GENRE_TYPE.DARK_ITALO, true);
    this.handleFilterClick(GENRE_TYPE.DARK_EUROBEAT, true);
    this.handleFilterClick(GENRE_TYPE.ELECTRO_ROCK, true);
    this.handleFilterClick(GENRE_TYPE.SPYWAVE, true);
    this.handleFilterClick(GENRE_TYPE.SPYFI, true);
    this.handleFilterClick(GENRE_TYPE.CELESTIAL_BODY_MUSIC, true);
    this.handleFilterClick(GENRE_TYPE.SID_COVERS, true);
    this.handleFilterClick(GENRE_TYPE.EBM, true);
    this.handleFilterClick(GENRE_TYPE.DARKWAVE, true);
    this.handleFilterClick(GENRE_TYPE.DANCE, true);
    this.handleFilterClick(GENRE_TYPE.DREAMPOP, true);
    this.handleFilterClick(GENRE_TYPE.REMIX, true);
    this.handleFilterClick(GENRE_TYPE.HOUSE, true);
    this.handleFilterClick(GENRE_TYPE.EXPERIMENTAL, true);
    this.handleFilterClick(GENRE_TYPE.ELECTRO, true);
    this.handleFilterClick(GENRE_TYPE.POP, true);
    this.handleFilterClick(GENRE_TYPE.SYNTHPOP, true);
    this.handleFilterClick(GENRE_TYPE.COVER, true);
    this.handleFilterClick(GENRE_TYPE.INDUSTRIAL, true);
    this.handleFilterClick(GENRE_TYPE.HARD_RAGE, true);
    this.handleFilterClick(GENRE_TYPE.BASS_JAMMIN, true);
  }

  handleFiltersShowOne()
  {
  }
  
  handleFilterClick(filterID, filterEnabled)
  {
    if (this.state.showLogs)
    {
      console.log("FILTER ", filterID);
      console.log("filterEnabled ", filterEnabled);
    }

    let flt = this.state.filters;
    flt[[filterID]] = filterEnabled;
    this.setState({ filters : flt }, () =>
    {
      //  console.log(this.state.filters)
    });

    // this.setState({ triggerUpdate: !this.state.triggerUpdate });

    for (var i=1; i<songs.length; i++)
    {
      let song = songs[i];

      // REFAC

      this.filterGenre(song, filterEnabled, filterID, "Soundtrack", GENRE_TYPE.SOUNDTRACK);
      this.filterGenre(song, filterEnabled, filterID, "Italo", GENRE_TYPE.ITALO_DISCO);
      this.filterGenre(song, filterEnabled, filterID, "Eurobeat", GENRE_TYPE.EUROBEAT);
      this.filterGenre(song, filterEnabled, filterID, "Dark Italo", GENRE_TYPE.DARK_ITALO);
      this.filterGenre(song, filterEnabled, filterID, "Dark Eurobeat", GENRE_TYPE.DARK_EUROBEAT);
      this.filterGenre(song, filterEnabled, filterID, "Electro Rock", GENRE_TYPE.ELECTRO_ROCK);
      this.filterGenre(song, filterEnabled, filterID, "SpyWave", GENRE_TYPE.SPYWAVE);
      this.filterGenre(song, filterEnabled, filterID, "Spy-Fi", GENRE_TYPE.SPYFI);
      this.filterGenre(song, filterEnabled, filterID, "CBM (Celestial Body Music)", GENRE_TYPE.CELESTIAL_BODY_MUSIC);
      this.filterGenre(song, filterEnabled, filterID, "SID Covers", GENRE_TYPE.SID_COVERS);
      this.filterGenre(song, filterEnabled, filterID, "EBM", GENRE_TYPE.EBM);
      this.filterGenre(song, filterEnabled, filterID, "Darkwave", GENRE_TYPE.DARKWAVE);
      this.filterGenre(song, filterEnabled, filterID, "Dance", GENRE_TYPE.DANCE);
      this.filterGenre(song, filterEnabled, filterID, "Dreampop", GENRE_TYPE.DREAMPOP);
      this.filterGenre(song, filterEnabled, filterID, "Remix", GENRE_TYPE.REMIX);
      this.filterGenre(song, filterEnabled, filterID, "House", GENRE_TYPE.HOUSE);
      this.filterGenre(song, filterEnabled, filterID, "Experimental", GENRE_TYPE.EXPERIMENTAL);
      this.filterGenre(song, filterEnabled, filterID, "Electro", GENRE_TYPE.ELECTRO);
      this.filterGenre(song, filterEnabled, filterID, "Pop", GENRE_TYPE.POP);
      this.filterGenre(song, filterEnabled, filterID, "Synthpop", GENRE_TYPE.SYNTHPOP);
      this.filterGenre(song, filterEnabled, filterID, "Cover", GENRE_TYPE.COVER);
      this.filterGenre(song, filterEnabled, filterID, "Industrial", GENRE_TYPE.INDUSTRIAL);
      this.filterGenre(song, filterEnabled, filterID, "Hard Rage", GENRE_TYPE.HARD_RAGE);
      this.filterGenre(song, filterEnabled, filterID, "Bass Jammin'", GENRE_TYPE.BASS_JAMMIN);
    }

    // calculate number of filtered

    let numberOfFiltered = 0;
    for (var i=1; i<songs.length; i++)
    {
      if (songs[i].visible === false)
      {
        numberOfFiltered += 1;
      }
    }
    this.setState({ songsFiltered: numberOfFiltered });
  }

  hideSong(song) { song.visible = false; this.setState({ triggerUpdate: !this.state.triggerUpdate }); }
  showSong(song) { song.visible = true; this.setState({ triggerUpdate: !this.state.triggerUpdate }); }

  filterGenre(song, filterEnabled, filterID, genreDisplayName, genreType)
  {
    if (filterEnabled === true && filterID === genreType && song.genre.includes(genreDisplayName)) { this.hideSong(song); }
    if (filterEnabled === false && filterID === genreType && song.genre.includes(genreDisplayName)) { this.showSong(song); }
  }

  render()
  {
    return (
      <div className="App">
      <Container>

        <PanelTop
          songData={this.state.songData}
          currentSongCard={1}
          imgSrc={this.state.songData.albumArt}
          handlePanelSettingsOpen={this.handlePanelSettingsOpen}
          handlePanelFiltersOpen={this.handlePanelFiltersOpen}
          handleExitApp={this.handleExitApp}
          handleJumpToLyrics={this.handleJumpToLyrics}         
          handleJumpToCredits={this.handleJumpToCredits}         
          handleJumpToGear={this.handleJumpToGear}
          handleJumpToFavorites={this.handleJumpToFavorites}
        ></PanelTop>

        <PanelMain
          songs={songs}
          cardType={this.state.cardType}
          handleSongCardClick={this.handleSongCardClick}
          handleSongCardMouseOver={this.handleSongCardMouseOver}
        ></PanelMain> 

        <PanelBottom
          songData={this.state.songData}
          currentSongCard={1}
          imgSrc={this.state.songData.albumArt}
        ></PanelBottom>

        {this.state.panelPlayerIsOpened && (
        <PanelPlayer
          songData={this.state.songData}
          handleOnClick={this.handlePanelPlayerClick}
          handleClose={this.handlePanelPlayerClose}
          handleMinimize={this.handlePanelPlayerMinimize}
          renderwareWidth={this.state.renderwareWidth}
          renderwareHeight={this.state.renderwareHeight}
          minimized={this.state.panelPlayerIsMinimized}
        >PLAYER</PanelPlayer>
        )}

        {this.state.panelSettingsIsOpened && (
        <PanelSettings
          cardType={this.state.cardType}
          handleClose={this.handlePanelSettingsClose}
          handleSetCardTypeA={this.handleSetCardTypeA}
          handleSetCardTypeB={this.handleSetCardTypeB}
          handleSetCardTypeC={this.handleSetCardTypeC}
          // handleSongCardClick={this.handleSongCardClick}
          // handleSongCardMouseOver={this.handleSongCardMouseOver}
        ></PanelSettings> 
        )}

        {this.state.panelFiltersIsOpened && (
        <PanelFilters
          filters={this.state.filters}
          songsTotal={this.state.songsTotal}
          songsFiltered={this.state.songsFiltered}
          handleFilterClick={this.handleFilterClick}
          handleClose={this.handlePanelFiltersClose}
          handleFiltersShowAll={this.handleFiltersShowAll}
          handleFiltersHideAll={this.handleFiltersHideAll}
          handleFiltersShowOne={this.handleFiltersShowOne}
        ></PanelFilters>
        )}

        {this.state.panelErrorIsOpened && (
        <PanelError
          handleClose={this.handlePanelErrorClose}
          errorMessage={this.state.errorMessage}
        ></PanelError> 
        )}

      </Container>
      </div>
    );
  }
}

export default App;
