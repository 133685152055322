
import React from 'react';
import './PanelSettings.css';

import SongCard from './SongCard'
import SongCardBig from './SongCardBig'

import ButtonCategory from './ButtonCategory'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonMain from './ButtonMain';
import BUTTON_TYPE from '../enums/BUTTON_TYPE';

class PanelSettings extends React.Component
{
  constructor(props)
  {
    super(props);

    this.CARD_TYPES = 
    {
      SMALL_CARD : 1,
      ROW_CARD :2,
      BIG_CARD : 3
    }

    this.state = 
    {
      expanded: true,
    }
  }

  componentDidUpdate()
  {
    switch(this.props.cardType)
    {
      case  this.CARD_TYPES.SMALL_CARD:   document.getElementById("panel-settings-row-card-type").innerHTML = "CARD TYPE: CARD TYPE A - SMALL"; break;
      case  this.CARD_TYPES.ROW_CARD:     document.getElementById("panel-settings-row-card-type").innerHTML = "CARD TYPE: CARD TYPE B - ROW"; break;
      case  this.CARD_TYPES.BIG_CARD:     document.getElementById("panel-settings-row-card-type").innerHTML = "CARD TYPE: CARD TYPE C - BIG"; break;
    }
  }

  handleSongCardOnClick(songData)
  {
    // this.props.handleSongCardClick(songData);
  }

  handleSongCardMouseOver(songData)
  {
    // this.props.handleSongCardMouseOver(songData);
  }

  handleSetColorThemeA() { document.body.style.backgroundColor = "#151b1e"; }
  handleSetColorThemeB() { document.body.style.backgroundColor = "steelblue"; }
  handleSetColorThemeC() { document.body.style.backgroundColor = "royalblue"; }
  handleSetColorThemeD() { document.body.style.backgroundColor = "darkslategrey"; }
  handleSetColorThemeE() { document.body.style.backgroundColor = "turquoise"; }
  handleSetColorThemeF() { document.body.style.backgroundColor = "crimson"; }
  handleSetColorThemeG() { document.body.style.backgroundColor = "violet"; }
  handleSetColorThemeH() { document.body.style.backgroundColor = "yellow"; }
  handleSetColorThemeI() { document.body.style.backgroundColor = "yellowgreen"; }
  handleSetColorThemeJ() { document.body.style.backgroundColor = "blueviolet"; }
  handleSetColorThemeK() { document.body.style.backgroundColor = "hotpink"; }
  handleSetColorThemeL() { document.body.style.backgroundColor = "mediumorchid"; }
  handleSetColorThemeM() { document.body.style.backgroundColor = "mintcream"; }
  handleSetColorThemeN() { document.body.style.backgroundColor = "wheat"; }
  handleSetColorThemeO() { document.body.style.backgroundColor = "slategray"; }

  render()
  {
    return(
      <div className="panel-settings">
        <div className="panel-settings-container">
          {/* <Row> */}
            <div className="panel-settings-title-bar noselect">
              <div className="panel-settings-title-bar-label noselect">
                Settings
              </div>
            </div>

            <div className="panel-settings-buttons">
              <ButtonMain type={BUTTON_TYPE.CARD_TYPE_A} onClick={this.props.handleSetCardTypeA} />
              <ButtonMain type={BUTTON_TYPE.CARD_TYPE_B} onClick={this.props.handleSetCardTypeB} />
              <ButtonMain type={BUTTON_TYPE.CARD_TYPE_C} onClick={this.props.handleSetCardTypeC} />

              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_A} onClick={this.handleSetColorThemeA} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_B} onClick={this.handleSetColorThemeB} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_C} onClick={this.handleSetColorThemeC} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_D} onClick={this.handleSetColorThemeD} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_E} onClick={this.handleSetColorThemeE} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_F} onClick={this.handleSetColorThemeF} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_G} onClick={this.handleSetColorThemeG} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_H} onClick={this.handleSetColorThemeH} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_I} onClick={this.handleSetColorThemeI} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_J} onClick={this.handleSetColorThemeJ} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_K} onClick={this.handleSetColorThemeK} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_L} onClick={this.handleSetColorThemeL} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_M} onClick={this.handleSetColorThemeM} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_N} onClick={this.handleSetColorThemeN} />
              <ButtonMain type={BUTTON_TYPE.COLOR_SCHEME_O} onClick={this.handleSetColorThemeO} />

              <ButtonMain type={BUTTON_TYPE.EXIT} onClick={this.props.handleClose} />
            </div>

            <div className="panel-settings-rows">
              <div className="panel-settings-row" id="panel-settings-row-card-type">
                CARD TYPE: A - SMALL CARD
              </div>
            </div>

          {/* </Row> */}
        </div>
      </div>
    );
  }
}

// TODO: Color schemes

// - Jump into the Yellow
// - Olive Greenfields

// Scrollbar - Not Yellow


// PanelPlayer minimized mode

// filters


// sorters ()
// sortBy:
// Rating - according to artist (self dis mostly, but not always)

export default PanelSettings;