
// ***********************************************************************
// ** Player Scrubber
// ***********************************************************************

import React from 'react';

class Scrubber extends React.Component
{
  constructor(props)
  {
    super(props);

    this.canvasRef = React.createRef();

    this.state = 
    {
      dummy: 0,
      x: 0,
    }

    this.updateAnimation = this.updateAnimation.bind(this);
  }

  componentDidMount()
  {
    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  componentWillUnmount()
  {
    cancelAnimationFrame(this.raf);
  }

  updateAnimation()
  {
    // console.log("Scrubber UPDATE> POS: ", this.state.x);

    this.setState({ x: this.props.currentTime + 4 });
    // document.getElementById("panel-player-scrubber-box").style.left = this.state.x + "px";

    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  componentDidUpdate()
  {
    // console.log("componentDidUpdate");
  }

  render()
  {
    return <div>

      <div className="panel-player-scrubber-box" id="panel-player-scrubber-box" >
      </div>

    </div> 
  }
}
export default Scrubber;