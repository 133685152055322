
import React from 'react';
import './ButtonScaler.css';

import { FaFireExtinguisher } from 'react-icons/fa';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { FaRocket } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { FaWindowClose } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { GiCardJoker } from 'react-icons/gi';
import { FaFilter } from 'react-icons/fa';
import { GiStabbedNote } from 'react-icons/gi';

// import { GiExitDoor } from 'react-icons/gi';
// import { FaYoutube } from 'react-icons/fa';

class ButtonScaler extends React.Component
{
  constructor()
  {
    super();
    this.state = 
    {
    }
  }

  renderExpandedMode()
  {
  }

  renderIcon()
  {
    switch (this.props.icon)
    {
      case "Soundtrack": return(<FaUmbrellaBeach />); break;
      case "Italo Disco": return(<FaUmbrellaBeach />); break;
      case "Eurobeat": return(<FaFireExtinguisher />); break;
      case "Dark Italo": return(<FaUmbrellaBeach />); break;
      case "Dark Eurobeat": return(<FaFireExtinguisher />); break;
      case "Electro Rock": return(<FaUmbrellaBeach />); break;
      case "SpyWave": return(<FaUmbrellaBeach />); break;
      case "Spy-Fi": return(<FaUmbrellaBeach />); break;
      case "Celestial Body Music": return(<FaRocket />); break;

      case "FaUmbrellaBeach": return(<FaUmbrellaBeach />); break;
      case "FaRocket": return(<FaRocket />); break;
      case "FaReact": return(<FaReact />); break;
      case "Settings": return(<IoMdSettings />); break;
      case "Filters": return(<FaFilter />); break;
      case "Close": return(<FaWindowClose />); break;
      case "Exit": return(<FaWindowClose />); break;
      case "Card": return(<GiCardJoker />); break;
      case "Lyrics": return(<GiStabbedNote />); break;

      default: return(<FaUmbrellaBeach />);
    }
  }

  render()
  {
    let divStyle = null;

    if (this.props.align === "right")
    {
      divStyle = 
      {
        right: this.props.x + "px",
        top: this.props.y + "px",
        width: this.props.width + "px",
        height: this.props.height + "px",
      }
    }

    else
    {
      divStyle = 
      {
        left: this.props.x + "px",
        top: this.props.y + "px",
        width: this.props.width + "px",
        height: this.props.height + "px",
      }
    }
  
    let iconStyle = null;

    iconStyle =
    {
      // fontSize: (this.props.width / 2),
      // lineHeight: (this.props.height /4) + "px",
      // top: this.props.height / 8 + "px",
    }

    return(
      <div className="button-scaler spacing-bottom highlight" style={divStyle} onClick={this.props.onClick} >

        <div className="button-scaler-icon highlight" style={iconStyle}>
          {this.renderIcon()}
        </div>

        <div className="button-scaler-title">
          {this.props.title}
        </div>

      </div>
    );
  }
}

export default ButtonScaler;

// var divStyle = {
//   color: 'white',
//   backgroundImage: 'url(' + imgUrl + ')',
//   WebkitTransition: 'all', // note the capital 'W' here
//   msTransition: 'all' // 'ms' is the only lowercase vendor prefix
// };
