
// ***********************************************************************
// ** Player VolumeBar
// ***********************************************************************

import React from 'react';

class VolumeBar extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      dummy: 0,
    }
  }

  componentDidUpdate()
  {
  }

  componentDidMount()
  {
  }

  componentWillUnmount()
  {
  }

  updateAnimation()
  {
  }

  componentDidUpdate()
  {
  }

  render()
  {
    if (this.props.disabled === true)
    {
      return <div className="panel-player-volume-bar-disabled"></div> 
    }
    else
    {
      return <div className="panel-player-volume-bar"></div> 
    }
  }
}
export default VolumeBar;