
import React from 'react';
import './SongCardRow.css';

import { FaYoutube } from 'react-icons/fa';
import { IoIosStarOutline } from 'react-icons/io';
import { IoIosStar } from 'react-icons/io';
import { FaExpand } from 'react-icons/fa';

class SongCardRow extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      expanded: true,
    }

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  renderExpandedMode()
  {
  }

  handleOpenYoutube()
  {
    window.open(this.props.songData.youtubeLink, "_blank");
  }

  renderYouTube()
  {
    if (this.props.songData.youtube)
    {
      return(
        <div className="song-card-row-icon-social-big social-yt highlight" onClick={() => this.handleOpenYoutube()} > <FaYoutube /> </div>
      );
    }
    else
    {
      return(
        <div className="song-card-row-icon-social-big social-yt disabled" > <FaYoutube /> </div>
      );
    }
  }

  renderStars()
  {
    switch (this.props.songData.rating)
    {
      case 0: return(<div>LVL: <IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
      case 1: return(<div>LVL: <IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
      case 2: return(<div>LVL: <IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
      case 3: return(<div>LVL: <IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
      case 4: return(<div>LVL: <IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /></div>); break;
      case 5: return(<div>LVL: <IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /></div>); break;
    }
  }

  handleMouseOver()
  {
    this.props.handleMouseOver(this.props.songData);
  }

  handleOnClick()
  {
    this.props.handleOnClick(this.props.songData);
  }

  // title={songs[i].title}
  // albumArt={songs[i].albumArt}
  // artist={songs[i].artist}
  // song={songs[i].song}
  // album={songs[i].album}
  // writtenBy={songs[i].writtenBy}
  // year={songs[i].year}
  // genre={songs[i].genre}
  // songDOB={songs[i].songDOB}
  // youtube={songs[i].youtube}
  // youtubeLink={songs[i].youtubeLink}
  // rating={songs[i].rating}
  // technology={songs[i].technology}
  // linerNotes={songs[i].linerNotes}

  render()
  {
    return(
      <div className="song-card-row" onMouseOver={this.handleMouseOver} onClick={this.handleOnClick} >

          <div className="song-card-row-album-art">
            <img src={this.props.songData.albumArt} className="album-art" alt="albumArt" />
          </div>

          <div className="song-card-row-lines-container">

            {/* <div className="song-card-row-line">
              <div className="song-card-row-info-key">Title:</div>
              <div className="song-card-row-info-value">{this.props.songData.title}</div>
            </div>        */}
            <div className="song-card-row-line">
              <div className="song-card-row-info-key">Artist:</div>
              <div className="song-card-row-info-value">{this.props.songData.artist}</div>
            </div>       
            <div className="song-card-row-line">
              <div className="song-card-row-info-key">Song:</div>
              <div className="song-card-row-info-value">{this.props.songData.song}</div>
            </div>

          </div>

          {this.renderYouTube()}

          <div className="song-card-row-stars">
            {this.renderStars()}
          </div>

          <div className="song-card-row-expand">
            <FaExpand />
          </div>

          {/* {this.props.title} */}
      </div>
    );
  }
}

export default SongCardRow;