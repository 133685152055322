
// ***********************************************************************
// ** Player VolumeDisplay
// ***********************************************************************

import React from 'react';
import VolumeBar from './VolumeBar';

import { IoIosStarOutline } from 'react-icons/io';
import { IoIosStar } from 'react-icons/io';

class VolumeDisplay extends React.Component
{
  constructor(props)
  {
    super(props);

    this.canvasRef = React.createRef();

    this.state = 
    {
      dummy: 0,
      volume: 0
    }

    this.updateAnimation = this.updateAnimation.bind(this);
  }

  componentDidUpdate()
  {
    console.log("this.state.volume", this.state.volume)
    this.setState({ dummy: this.state.dummy + 1 });
  }

  componentDidMount()
  {
    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  componentWillUnmount()
  {
    cancelAnimationFrame(this.raf);
  }

  renderStars()
  {
    let currentVolume = this.props.volume;

    // switch (currentVolume)
    // {
    //   case 0: return(<div><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 1: return(<div><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 2: return(<div><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 3: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 4: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 5: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 6: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 7: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 8: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /><IoIosStarOutline /></div>); break;
    //   case 9: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStarOutline /></div>); break;
    //   case 10: return(<div><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /><IoIosStar /></div>); break;
    // }

    let volumeBars = [];
    volumeBars.push(<div></div>);

    for (var i=0; i<currentVolume; i++)
    {
      volumeBars.push(<VolumeBar key={"volume-bar-" + i} />);
    }

    for (var i=currentVolume; i<10; i++)
    {
      volumeBars.push(<VolumeBar key={"volume-bar-" + i} disabled={true}/>);
    }

    return volumeBars;
  }

  updateAnimation()
  {
    this.raf = requestAnimationFrame(this.updateAnimation);
  }

  componentDidUpdate()
  {
    // console.log("componentDidUpdate");
  }

  render()
  {
    return <div 
           >

             {this.renderStars()}
    </div> 
  }
}
export default VolumeDisplay;